body, html {
  padding:0;
  margin:0;
  background-color: ghostwhite;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.orders_table_multiline_column {
  white-space: break-spaces !important;
  display: flex !important;
  align-items: center !important;
  line-height: inherit !important;
}

.justify_center {
  justify-content: center;
}
.MuiDataGrid-row:has(.MuiDataGrid-cell>.row-admin) {
  color: #3f51b5;
  font-weight: 600;
}

.row-admin>button {
  font-weight: 600;
}